import React from "react";
import Layout from "../layouts/layout";
import Heading1 from "../components/rich-text-elements/heading-1";
import { LocationProvider } from "../contexts/LocationContext";

export default class NotFound extends React.Component {
  render() {
    return (
      <LocationProvider>
        <Layout title="404 Page not found" metaDescription={undefined} metaImageUrl={undefined} noIndex={true}>
          <div className="container px-6 mx-auto pt-32 pb-v-1/4">
            <Heading1>404</Heading1>
            <p>Sorry, this page was either not found, missing, or no longer exists.</p>
          </div>
        </Layout>
      </LocationProvider>
    );
  }
}
